<script lang="ts" setup>
const props = defineProps<{
	icon: string;
	svg?: boolean;
	width?: string;
}>();
</script>

<template>
	<i
		v-if="!svg"
		:class="`iconoir-${props.icon} icon-object`"
		:style="{
			fontSize: props.width ?? '16px'
		}"
	/>
	<img
		v-else
		:alt="'Icon ' + props.icon"
		:src="`/images/icons/${icon}.svg`"
		class="icon-object"
	/>
</template>

<style lang="scss" scoped>
.icon-object {
	@apply inline-block select-none;
}
</style>
